import ContainerMain from "src/pages/common/ContainerMain"
import banner2 from "images/Channels/SocialCommerce/KOL_management.png"
import "./ModelConcept.scss"
import { useFormatter } from "helpers/i18n"

export default function ModelConcept() {
  const { __ } = useFormatter()

  return (
    <>
      <div className="jvqydtlwmi">
        <ContainerMain
          alignType={
            typeof window !== "undefined" &&
            window.matchMedia("screen and (min-width: 1024px)").matches
              ? "left"
              : "all"
          }
        >
          <div className="ekeupmfcjh">
            <div className="aarvervebz">
              <div className="exzfjblavf-header mobile">
                <div className="exzfjblavf">
                  <div className="ojxrugtwdy">{`${__({
                    defaultMessage: "KOL Management",
                  })}`}</div>
                  <div className="prmtfwxwnp">
                    {__({
                      defaultMessage:
                        "Actively collaborate with social influencers to engage shoppers with social campaigns and hot deals.",
                    })}
                  </div>
                </div>
              </div>
              <div className="jqqjzvroem">
                <div className="ilgwvxaowu">
                  <img
                    className="ziiswpbgbf"
                    src={banner2}
                    alt="KOL Management"
                  />
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
        <div className="kol-management-detail">
          <ContainerMain>
            <div className="kol-management-detail__container">
              <div className="kol-management-detail__wrapper">
                <div className="exzfjblavf-header pc">
                  <div className="exzfjblavf">
                    <div className="ojxrugtwdy">{`${__({
                      defaultMessage: "KOL Management",
                    })}`}</div>
                    <div className="prmtfwxwnp">
                      {__({
                        defaultMessage:
                          "Actively collaborate with social influencers to engage shoppers with social campaigns and hot deals.",
                      })}
                    </div>
                  </div>
                </div>
                <div className="ekeupmfcjh-2">
                  <div className="qgzhosdkrg">
                    <div className="nltyhjegpm">
                      <div className="lkgnnbdgqn">
                        {__(
                          {
                            defaultMessage: "KOL{br}Sourcing",
                          },
                          { br: <br /> }
                        )}
                      </div>
                      <div className="qrltsfdilu">
                        {__({
                          defaultMessage:
                            "Key opinion leaders (KOL) or thought leaders in their respective communities are nowadays a crucial partnership for brands to identify and engage the right audience, that directly contributes to increased ROI and commercial success. We provide brands with a seamless process of KOL Management that includes the identification and segmentation of most suitable KOL, who align with your business objective.",
                        })}
                      </div>
                    </div>
                    <div className="nltyhjegpm">
                      <div className="lkgnnbdgqn">
                        {__(
                          { defaultMessage: "KOL{br}Strategy planning" },
                          { br: <br /> }
                        )}
                      </div>
                      <div className="qrltsfdilu">
                        {__({
                          defaultMessage:
                            "We create communication strategy plans for brands to collaborate with KOLs to ensure the right target audience engagement and the creation of brand and product awareness. Furthermore, we provide a seamless tracking of interactions between parties, develop ready-to-use contracts and allow a worry-free payment process, so brands can focus on the most important: their customers.",
                        })}
                      </div>
                    </div>
                    <div className="nltyhjegpm">
                      <div className="lkgnnbdgqn">
                        {__(
                          { defaultMessage: "KOL{br}Results management" },
                          { br: <br /> }
                        )}
                      </div>
                      <div className="qrltsfdilu">
                        {__({
                          defaultMessage:
                            "We closely engage and interact with KOLs on an continuous basis, to ensure KOLs convert traffic to sales and reflect of brand value and goals.",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContainerMain>
        </div>
      </div>
    </>
  )
}
